<template>
  <main class="pricing-landing">
    <section class="section1">
      <div class="center">
        <h2 class="landing-text-center">모든 시작은 무료 입니다</h2>
        <p class="landing-text-center">
          스튜디오 최초 가입 시 스타터로 설정되며, 이후 프로 및<br class="mo" />
          엔터프라이즈로 제안을 받으실 수 있습니다.<br />
          스튜디오를 통한 판매 수익이 발생될 경우에만<br class="mo" />
          이용 수수료가 부과됩니다.
        </p>

        <div class="plan-list">
          <div class="plan hidden">
            <div>
              <h3>Starter</h3>
            </div>
            <p class="desc">
              10초만에 시작하는 퍼스널 브랜딩<br />
              지금 무료로 체험해보세요!
            </p>
            <div class="func-wrapper">
              <h4>제공 기능</h4>
              <ul class="functions">
                <li class="checked black">
                  <span>나만의 브랜드 홈 만들기</span>
                  <ul class="exist-sub">
                    <li>∙ 위젯으로 만드는 포트폴리오 사이트</li>
                    <li>∙ SNS와 각종 링크 연결 가능</li>
                  </ul>
                </li>
                <li class="checked black">
                  <span>커뮤니티 기능</span>
                  <ul class="exist-sub">
                    <li>∙ 다양한 형식의 게시판 세팅 기능</li>
                  </ul>
                </li>
                <li class="checked black">
                  <span>데이터 분석</span>
                  <ul class="exist-sub">
                    <li>∙ 브랜드 홈 방문 및 팔로우 데이터 분석</li>
                  </ul>
                </li>
              </ul>
            </div>

            <p class="promotion-title">
              1,000명 이상의 크리에이터가<br class="mo" />
              활용하고 <br class="pc" />있어요!
            </p>

            <div class="images">
              <img
                v-for="(item, index) in [1, 2, 3, 4]"
                :key="index"
                :srcset="`/assets/web_image/pricing/starter/starter0${item}.webp, /assets/web_image/pricing/starter/starter0${item}.jpg`"
                width="62"
                :alt="`starter-avatar0${item}`"
              />
            </div>

            <button class="card-cta__btn" @click="actions.goToSnsLogin()">
              무료 체험하기
            </button>
          </div>

          <div class="plan hidden">
            <div>
              <h3>Pro</h3>
            </div>
            <p class="desc">
              크리에이터를 위한 최강의 IP 수익화 도구<br />커뮤니티 운영과
              수익을 한번에 해결하세요!<br />
              <span class="sub-desc"
                >(지원시 선정 과정을 거쳐 계약이 진행됩니다.)</span
              >
            </p>
            <div class="func-wrapper">
              <h4>제공 기능</h4>
              <ul class="functions">
                <li class="checked">
                  <span class="active">스타터 플랜에 포함된 모든 기능</span>
                </li>
                <li class="checked">
                  <span>라이브 이벤트 수익화</span>
                  <ul class="exist-sub">
                    <li>∙ 1:1 화상 라이브, 1:N 라이브 스트리밍 기능</li>
                    <li>∙ 세미나, 컨퍼런스, 콘서트 등 라이브 이벤트 판매</li>
                  </ul>
                </li>
                <li class="checked">
                  <span>유료 콘텐츠 수익화</span>
                  <ul class="exist-sub">
                    <li>∙ VOD, PDF 등 콘텐츠 유료 판매 기능</li>
                  </ul>
                </li>
                <li class="checked">
                  <span>전담 매니지먼트</span>
                  <ul class="exist-sub">
                    <li>∙ 기획, 마케팅, 운영 활동 지원</li>
                    <li>∙ IP 팬덤 관리 지원</li>
                  </ul>
                </li>
              </ul>
            </div>

            <p class="promotion-title">
              평균 월 7,800 만원의 수익이<br class="mo" />
              발생하고 <br class="pc" />있어요!
            </p>
            <p class="promotion-sub-title pc">
              ※ 상위 20% 기준, 22년 2월 ~ 23년 3월
            </p>

            <div class="images">
              <img
                v-for="(item, index) in [1, 2, 3, 4]"
                :key="index"
                :srcset="`/assets/web_image/pricing/pro/pro0${item}.webp, /assets/web_image/pricing/enterprise/enterprise${item}.jpg`"
                width="62"
                :alt="`pro-avatar0${item}`"
              />
            </div>

            <button class="card-cta__btn" @click="actions.goToOpenHourForm()">
              프로 지원하기
            </button>
          </div>

          <div class="plan hidden">
            <div>
              <h3>Enterprise</h3>
            </div>
            <p class="desc">
              글로벌 IP와 팬덤 수익화의 모든 과정,<br />전문가와 함께 All In
              One으로 진행하세요!
            </p>
            <div class="func-wrapper">
              <h4>제공 기능</h4>
              <ul class="functions">
                <li class="checked">
                  <span class="active">스타터 플랜에 포함된 모든 기능</span>
                </li>
                <li class="checked">
                  9종 솔루션 ∙ IP 비즈니스 서포트 패키지 <br class="pc" />제공
                </li>
                <li class="checked">
                  <span>빅크 콘서트 솔루션</span>
                  <ul class="exist-sub">
                    <li>∙ 채팅 기능 (별도) 및 팬 인터렉션 기반 라이브</li>
                    <li>∙ 글로벌 팬덤 대상 마케팅 진행</li>
                    <li>∙ 오프라인-온라인 하이브리드 행사 지원</li>
                  </ul>
                </li>
                <li class="checked">
                  <span>빅크 컨퍼런스 솔루션</span>
                  <ul class="exist-sub">
                    <li>∙ 하이브리드 행사 운영 및 라이브 송출 지원</li>
                    <li>∙ 사후 리포트 제공 및 데이터 분석</li>
                  </ul>
                </li>
              </ul>
            </div>

            <p class="promotion-title">
              글로벌 업계 최고의 IP 비즈니스<br class="mo" />
              기업들과 <br class="pc" />함께하고 있습니다
            </p>

            <div class="images brand-images">
              <img
                v-for="(item, index) in [1, 2, 3]"
                :key="index"
                :srcset="`/assets/web_image/pricing/enterprise/enterprise0${item}.webp, /assets/web_image/pricing/enterprise/enterprise${item}.jpg`"
                width="87"
                :alt="`enterprise-logo0${item}`"
              />
            </div>

            <button class="card-cta__btn" @click="actions.goToEnterprise()">
              자세히 보기
            </button>
          </div>
        </div>
      </div>
    </section>
    <section id="solution" class="section2">
      <div class="center">
        <h2 class="hidden">
          모든 크리에이터를 위한 <br class="mo" />기본 솔루션
        </h2>
        <div class="pricing-solutions first hidden">
          <div
            v-for="(item, index) in solutionsFirst"
            :key="index"
            class="pricing-solution"
          >
            <div class="title">
              <span class="icon" v-html="item.icon"></span>
              <span>{{ item.title }}</span>
            </div>
            <ul class="list">
              <li
                v-for="(subItem, index) in item.descriptions"
                :key="index"
                class="item"
              >
                <div class="dot"></div>
                {{ subItem }}
              </li>
            </ul>
          </div>
        </div>
        <div class="pricing-solutions second hidden">
          <div
            v-for="(item, index) in solutionsSecond"
            :key="index"
            class="pricing-solution"
          >
            <div class="title">
              <span class="icon" v-html="item.icon"></span>
              <span>{{ item.title }}</span>
            </div>
            <ul class="list">
              <li
                v-for="(subItem, index) in item.descriptions"
                :key="index"
                class="item"
              >
                <div class="dot"></div>
                {{ subItem }}
              </li>
            </ul>
          </div>
        </div>
        <div class="marker">
          <div class="open">
            <span class="dot"></span>
            <span>Open</span>
          </div>
          <div class="coming-soon">
            <span class="dot"></span>
            <span>Coming Soon</span>
          </div>
        </div>
        <div class="sub-info2 hidden">
          <h3>크리에이터 시작 시 꼭 확인해주세요.</h3>
          <ul>
            <li>
              빅크 스튜디오(솔루션) 이용료는 무료이며, 상품 판매 후 매출 발생 시
              정산 정책 및 이용 수수료가 적용됩니다.
            </li>
            <li>
              빅크 스튜디오 가입 시 스타터로 기본 설정되어 활동을 게시할 수
              있으며, 프로 및 엔터프라이즈는 운영팀을 통해 개별 제안 및 연락
              드릴 예정입니다.
            </li>
            <li>
              프로 및 엔터프라이즈로의 변경 협의 과정에서 해당 플랜을 원치 않을
              경우 적용되지 않습니다.
            </li>
            <li>
              프로 및 엔터프라이즈 플랜에 제공되는 전담 매니지먼트 및 서포트
              패키지 등의 지원 내역은 개별 협의를 통해 이루어집니다.
            </li>
            <li>
              빅크 내 '추천 크리에이터' 또는 '추천 콘텐츠 노출'에 대한 큐레이션
              정책은 빅크에서 주관합니다.
            </li>
            <li>
              수수료 등 빅크 스튜디오에 대한 다양한 질문은
              <a
                href="https://bigc-im.notion.site/ef997064b0d54dea996afe4141ccb3c8"
                target="_blank"
                >빅크 스튜디오 크리에이터 가이드</a
              >를 참고, 또는
              <a href="https://bigcstudio.channel.io/" target="_blank"
                >고객 센터</a
              >로 문의(평일 10:00-18:00) 부탁드립니다.
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { onBeforeUnmount, onMounted } from "vue";
import { useRouter } from "vue-router";
import DatalayerEvents from "@/helper/datalayerEvents";
import { useHead } from "@vueuse/head";
import helper from "@/helper";

export default {
  name: "PricingLanding",
  setup() {
    useHead({
      title: "빅크 스튜디오 | 크리에이터가 되는 가장 빠른 방법",
      meta: [
        {
          name: "description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          name: "keywords",
          content: "빅크,셀럽,크리에이터,작가,뮤지션,전문가",
        },
        // OGP
        { property: "og:site_name", content: "BIGC - site name" },
        { property: "og:type", content: "website" },
        {
          property: "og:title",
          content: "빅크 스튜디오 | 크리에이터가 되는 가장 빠른 방법",
        },
        {
          property: "og:description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          property: "og:image",
          content:
            "https://www.bigc.studio/assets/web_image/seo/bigc-studio.png",
        },
        { property: "og:url", content: "https://www.bigc.studio/about" },

        {
          name: "twitter:title",
          content: "빅크 스튜디오 | 크리에이터가 되는 가장 빠른 방법",
        },
        {
          name: "twitter:description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.bigc.studio/assets/web_image/seo/bigc-studio.png",
        },
        // QQ
        { itemprop: "name", content: "Bigc studio" },
        {
          itemprop: "description",
          content:
            "1,000만 조회를 넘어 1,000명의 서포터즈를 만드세요. 빅크 스튜디오는 크리에이터를 위한 구독 페이지 및 온라인 라이브 솔루션을 제공합니다.",
        },
        {
          itemprop: "image",
          content:
            "https://www.bigc.studio/assets/web_image/seo/bigc-studio.png",
        },
      ],
    });

    const router = useRouter();

    const dataLayerEvents = new DatalayerEvents();

    const initCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "static";
      html.overflowY = "visible";
      body.position = "static";
      body.overflow = "visible";
      body.height = "auto";
      app.overflowX = "visible";
      app.overflowY = "visible";
    };
    onMounted(() => {
      initCss();
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });

      const hiddenElements = document.querySelectorAll(".hidden");
      hiddenElements.forEach((el) => observer.observe(el));
    });

    onBeforeUnmount(() => {
      resetCss();
    });

    const resetCss = () => {
      const html = document.getElementsByTagName("html")[0].style;
      const app = document.getElementById("app").style;
      const body = document.body.style;
      html.position = "fixed";
      html.overflowY = "hidden";
      body.position = "fixed";
      body.overflow = "hidden";
      body.height = "100%";
      app.overflowY = "scroll";
    };

    const solutionsFirst = [
      {
        title: "커스터마이징 브랜드 홈",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M2 6.53548C2 6.20113 2.1671 5.8889 2.4453 5.70343L7.4453 2.3701C7.7812 2.14617 8.2188 2.14617 8.5547 2.3701L13.5547 5.70343C13.8329 5.8889 14 6.20113 14 6.53548V13.0003C14 13.5526 13.5523 14.0003 13 14.0003H11C10.4477 14.0003 10 13.5526 10 13.0003V10.3003C10 10.1346 9.86569 10.0003 9.7 10.0003H6.3C6.13431 10.0003 6 10.1346 6 10.3003V13.0003C6 13.5526 5.55228 14.0003 5 14.0003H3C2.44772 14.0003 2 13.5526 2 13.0003V6.53548Z" fill="#8E1EFF"/>
</svg>`,
        descriptions: [
          "크리에이터 홈페이지",
          "링크 인 바이오",
          "팔로우 기능",
          "최대 50개 소셜 링크 연결",
          "유료 콘텐츠/상품 판매",
          "SNS 채널 피드 모아보기",
          "후원받기",
        ],
      },
      {
        title: "라이브 솔루션",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <rect x="1" y="2" width="14" height="11" rx="2" fill="#8E1EFF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.85551 7.55025C9.28379 7.24577 9.56319 6.74553 9.56319 6.18003C9.56319 5.25218 8.81101 4.5 7.88316 4.5C6.9553 4.5 6.20312 5.25218 6.20312 6.18003C6.20312 6.74462 6.48162 7.24415 6.90872 7.54877C5.79603 7.94731 5 9.01115 5 10.2609C5 10.3935 5.10745 10.5009 5.24 10.5009H10.5201C10.6527 10.5009 10.7601 10.3935 10.7601 10.2609C10.7601 9.0127 9.96605 7.94994 8.85551 7.55025Z" fill="white"/>
</svg>`,
        descriptions: [
          "강연 세미나 / 온라인 워크샵",
          "대규모 컨퍼런스",
          "실시간 글로벌 자동 자막 기능",
          "실시간 채팅 / 슬로우챗 기능",
          "AI 버추얼 MC : 라이브 중 자막, 투표",
          "1:1 프라이빗 코칭, 소그룹 코칭",
          "테마별 라이브 전용 AI MC 기능",
        ],
      },
      {
        title: "CRM 알림 도구",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2C5.16551 2 2.8677 4.23858 2.8677 7V8.91886C2.8677 8.9726 2.85881 9.02599 2.84137 9.07697L2.02138 11.4735C1.93276 11.7325 2.13065 12 2.4109 12H5.43385C5.43385 13.3807 6.58276 14.5 8 14.5C9.41724 14.5 10.5661 13.3807 10.5661 12H13.5891C13.8693 12 14.0672 11.7325 13.9786 11.4735L13.1586 9.07697C13.1412 9.02599 13.1323 8.9726 13.1323 8.91886V7C13.1323 4.23858 10.8345 2 8 2ZM9.53969 12H6.46031C6.46031 12.8284 7.14965 13.5 8 13.5C8.85035 13.5 9.53969 12.8284 9.53969 12Z" fill="#8E1EFF"/>
</svg>`,
        descriptions: [
          "문자, 카카오톡, 앱푸시 등 알림",
          "판매 관련 자동 알림",
          "구독 회원 전용 메시지 발송 기능",
          "크리에이터 전용 포인트",
        ],
      },
    ];

    const solutionsSecond = [
      {
        title: "유료 콘텐츠 판매",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <g clip-path="url(#clip0_5904_3833)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM5.46 3.71997L7.86 5.51997C8.18 5.75997 8.18 6.23997 7.86 6.47997L5.46 8.27997C5.06446 8.57662 4.5 8.29439 4.5 7.79997V4.19997C4.5 3.70554 5.06446 3.42331 5.46 3.71997Z" fill="#8E1EFF"/>
  </g>
  <defs>
    <clipPath id="clip0_5904_3833">
      <rect width="12" height="12" fill="white"/>
    </clipPath>
  </defs>
</svg>`,
        descriptions: [
          "VOD 영상 판매",
          "전자책 PDF Article",
          "라이브의 Replay 재판매 (수동)",
          "팟캐스트",
        ],
      },
      {
        title: "분석 데이터 인사이트",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 2C2.67157 2 2 2.67157 2 3.5V12.5C2 13.3284 2.67157 14 3.5 14H12.5C13.3284 14 14 13.3284 14 12.5V3.5C14 2.67157 13.3284 2 12.5 2H3.5ZM12.8536 4.85355C13.0488 4.65829 13.0488 4.34171 12.8536 4.14645C12.6583 3.95118 12.3417 3.95118 12.1464 4.14645L8 8.29289L7.20711 7.5C6.81658 7.10948 6.18342 7.10948 5.79289 7.5L3.14645 10.1464C2.95118 10.3417 2.95118 10.6583 3.14645 10.8536C3.34171 11.0488 3.65829 11.0488 3.85355 10.8536L6.5 8.20711L7.29289 9C7.68342 9.39052 8.31658 9.39052 8.70711 9L12.8536 4.85355Z" fill="#8E1EFF"/>
</svg>`,
        descriptions: [
          "유료 콘텐츠 별 판매 동향",
          "팬 / 팔로워 분석",
          "방문자 현황, 구매자 분석",
          "유입 채널, 국가 등 분석",
        ],
      },
      {
        title: "글로벌 결제 및 CS 지원",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4L11.6667 4C11.6667 3.44772 11.219 3 10.6667 3H4C3.44772 3 3 3.44772 3 4ZM12.6667 4V4.1138C13.4435 4.38836 14 5.12919 14 6V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V5V4C2 2.89543 2.89543 2 4 2H10.6667C11.7712 2 12.6667 2.89543 12.6667 4ZM10.75 9.75C11.1642 9.75 11.5 9.41421 11.5 9C11.5 8.58579 11.1642 8.25 10.75 8.25C10.3358 8.25 10 8.58579 10 9C10 9.41421 10.3358 9.75 10.75 9.75Z" fill="#8E1EFF"/>
</svg>`,
        descriptions: [
          "글로벌 결제 / 간편 결제 지원",
          "환불 등 CS 지원",
          "수익 자동 정산 기능 (12월)",
        ],
      },
      {
        title: "커뮤니케이션 도구 제공",
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <g clip-path="url(#clip0_5904_4011)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C12 9.31371 9.31371 12 6 12H2V10.4722C0.772501 9.37357 0 7.777 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM4 6.5C4.27614 6.5 4.5 6.27614 4.5 6C4.5 5.72386 4.27614 5.5 4 5.5C3.72386 5.5 3.5 5.72386 3.5 6C3.5 6.27614 3.72386 6.5 4 6.5ZM6 6.5C6.27614 6.5 6.5 6.27614 6.5 6C6.5 5.72386 6.27614 5.5 6 5.5C5.72386 5.5 5.5 5.72386 5.5 6C5.5 6.27614 5.72386 6.5 6 6.5ZM8.5 6C8.5 6.27614 8.27614 6.5 8 6.5C7.72386 6.5 7.5 6.27614 7.5 6C7.5 5.72386 7.72386 5.5 8 5.5C8.27614 5.5 8.5 5.72386 8.5 6Z" fill="#8E1EFF"/>
  </g>
  <defs>
    <clipPath id="clip0_5904_4011">
      <rect width="12" height="12" fill="white"/>
    </clipPath>
  </defs>
</svg>`,
        descriptions: [
          "팬 커뮤니티 : 공지 및 그룹 게시판",
          "그룹 및 1:1 라이브 채팅 기능",
        ],
      },
    ];

    const actions = {
      goToSnsLogin: () => {
        let eventObj = dataLayerEvents.pricingStart;
        dataLayerEvents.push(eventObj);
        router.push({ name: "auth.login" });
      },
      goToDetailLink: () => {
        router.push({ name: "web.enterprise" });
      },
      goToOpenHourForm: () => {
        helper.goToExternalUrl("https://tally.so/r/3NrrKl");
      },
      goToEnterprise: () => {
        router.push({ name: "web.enterprise" });
      },
    };
    return { actions, solutionsFirst, solutionsSecond };
  },
};
</script>

<style scoped src="./style.css"></style>
